module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-325241330","G-0L48KWBNZF","AW-882281191"],"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/meta/icon-512.png","short_name":"Nocturne X","name":"Nocturne X","background_color":"#090638","theme_color":"#090638","icons":[{"src":"src/assets/images/meta/icon-192.png","type":"image/png","sizes":"192x192"},{"src":"src/assets/images/meta/icon-512.png","type":"image/png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"644258f5b123473ed07dc900ecc31cbc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"851972628816266"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
